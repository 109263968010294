<template>
	<div class="home">
		<table-bankheader></table-bankheader>
  </div>
</template>

<script>
import tableBankheader from '@/components/pages/maint/bankheader/tableBankheader.vue';

export default {
	components: {
		'table-bankheader': tableBankheader,
	}
};
</script>

<style>

</style>
