<template>
     <v-dialog v-model="dialog" persistent max-width="50%">
     <!-- <app-loader v-model="isLoading"></app-loader> -->
      <template v-slot:activator="{ on }">
          <app-add-button v-bind:module="'bankheader'"></app-add-button>
        </template>
        <v-form
            ref="form"
            v-model="isvalid"
            lazy-validation
          >
          <v-card title>
           <v-toolbar
            flat
            dark
            color="primary"
          >
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn
              icon
              dark
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
         <v-card-text>
          
            <v-container grid-list-md>
            <v-layout wrap>
                <v-flex xs12 sm6 md6>                    
                <v-text-field 
                    v-model="editedItem.bank_code"
                    :counter="4"
                     label="Bank Code"
                    required
                    maxlength=4
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                <v-text-field 
                    v-model="editedItem.bank_name"
                    :counter="250"
                    label="Name"
                    required
                    maxlength=250
                ></v-text-field>
                </v-flex>
            </v-layout>
            </v-container>
        </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" dark @click="save">Save</v-btn>
              <v-btn color="red" text @click="close">Cancel</v-btn>
            </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAddButtonVue from '../../../partials/AppAddButton.vue';

export default {
	components: {
		'app-add-button': AppAddButtonVue
	},
	data: () => ({
		search: null,
		isvalid: true,
		bank_codeRules: [
			v => !!v || 'Bank Code is required',
			v => (v && v.length <= 5) || 'Bank Code must be less than 5 numbers',
		],
		bank_nameRules: [
			v => !!v || 'Bank Name is required',
			v => (v && v.length <= 250) || 'Bank Name must be less than 250 characters',
		],
	}),

	computed: {
		...mapGetters({
			bankheaders: 'bankheader/bankheaders',
			bankCode: 'bankheader/bankCode',
			bankName: 'bankheader/bankName',
			editedItem: 'bankheader/editedItem',
			defaultItem: 'bankheader/defaultItem',
			dialog: 'bankheader/dialog',
			editedIndex: 'bankheader/editedIndex',
			formTitle: 'bankheader/formTitle',
			valid: 'bankheader/valid',
			currUser: 'auth/currUser'
		}),
	}, 
	methods: {
		close () {
			setTimeout(() => {
				this.$refs.form.reset();
				this.$refs.form.resetValidation();
				this.$store.dispatch('bankheader/setValid',true);
				this.$store.dispatch('bankheader/setDialog',false);
				this.$store.dispatch('bankheader/setEditedIndex','-1');
				this.isvalid = false;
			}, 2000);
		},
		save () {
			this.$store.dispatch('bankheader/setValid',this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
			this.editedItem.edit_by = this.currUser.id;
			delete this.editedItem['updated_at'];
			delete this.editedItem['created_at'];
			if(this.valid){
				if (this.editedIndex > -1) {
					this.$store.dispatch('bankheader/updateBankheader',this.editedItem);
				} else {
					this.$store.dispatch('bankheader/saveBankheader',this.editedItem);
				}
				setTimeout(() => {
					this.close();
				}, 3000);
			}
		},
      
	}
};
</script>
